import { useContext } from 'react';
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Card,
  Avatar,
  Collapse,
  Button,
} from '@material-ui/core';

import { AppContext } from '../../../../contexts';

import Colors from '../../../../common/colors';
import { useStyles } from './BookingMeetingStyle';

import {
  getAllStudentMentors_allStudentMentors_mentor,
  getAllStudentMentors_allStudentMentors,
} from '../../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';

import Close_Icon_Blue from '../../../../img/blue-close.svg';
import Add_Advisor_Img from '../../../../img/plus-circle.svg';
import { isDiamondPackage } from '../../../../common/utility';
import CAP_LOGO_IMG from '../../../../img/cap-book-meeting.png';

const AdvisorsSelection = ({
  advisorId,
  allowAdvisorSelection,
  note,
  selectedAdvisors,
  setNote,
  setSelectedAdvisors,
  setMainAdvisor,
  setSelectedTimeslot,
  setAllowAdvisorSelection,
  handleClearIntervalAndTimer,
  intakeWidgetCondition,
  allStudentMentors,
  noKickOffMeeting,
}: {
  advisorId: number | null;
  allowAdvisorSelection: boolean;
  note: string;
  setNote: Function;
  selectedAdvisors: (
    | getAllStudentMentors_allStudentMentors_mentor
    | null
    | undefined
  )[];
  setSelectedAdvisors: Function;
  setMainAdvisor: Function;
  setSelectedTimeslot: Function;
  setAllowAdvisorSelection: Function;
  handleClearIntervalAndTimer: Function;
  intakeWidgetCondition: boolean;
  allStudentMentors: (getAllStudentMentors_allStudentMentors | null)[] | null;
  noKickOffMeeting: boolean;
}) => {
  const classes = useStyles();
  const { user } = useContext(AppContext);

  const userHasMoreMentors = !!allStudentMentors?.filter(
    (item) =>
      !Object.keys(
        selectedAdvisors?.find((mntr) => mntr?.id === item?.mentor?.id) || {}
      )?.length
  ).length;
  const consultant = allStudentMentors?.[0]?.consultant;

  return (
    <Box className={classes.addAdvisor}>
      <Box pb={!!selectedAdvisors.length ? 3 : 0}>
        <Typography variant="h5">Advisor</Typography>
      </Box>

      <Collapse in={!!selectedAdvisors.length}>
        <Grid className={classes.advisorCard} container spacing={2}>
          {selectedAdvisors?.map((advisor, index) => {
            const nonAoAdvisors = selectedAdvisors.filter(
              (adv) => adv?.advisorLevel !== 'Former Admissions Officer'
            );
            const diamondRemoveCondition = selectedAdvisors.length > 1;
            const removeAdvisorsCondition =
              selectedAdvisors.length > 1
                ? nonAoAdvisors.length > 1
                  ? true
                  : advisor?.advisorLevel === 'Former Admissions Officer'
                : false;
            return (
              <Grid item md={6} xs={12} key={index}>
                <Card className="advisorInfoCard">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box display="flex" alignItems="center">
                      <Avatar
                        className="advisorAvatar"
                        src={advisor?.pictureUrl || ''}
                        alt={advisor?.firstName || ''}
                      />

                      <Box>
                        <Typography variant="h5" noWrap>
                          {advisor?.firstName} {advisor?.lastName}
                        </Typography>

                        <Box color={Colors.BLUE_SEVEN}>
                          <Typography variant="body2" noWrap color="inherit">
                            {advisor?.advisorLevel}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>

                    {(intakeWidgetCondition
                      ? isDiamondPackage(user?.premiumPackageType || '')
                        ? diamondRemoveCondition
                        : removeAdvisorsCondition
                      : true) &&
                      allStudentMentors?.find(
                        (item) => item?.mentor?.id === advisor?.id
                      ) && (
                        <IconButton
                          aria-label="close"
                          className="advisorButton"
                          onClick={() => {
                            const _selectedAvds = [...selectedAdvisors];
                            const _filterdAdvs = _selectedAvds.filter(
                              (_selcted) => _selcted?.id !== advisor?.id
                            );
                            setSelectedAdvisors([..._filterdAdvs]);
                            handleClearIntervalAndTimer();
                            setMainAdvisor(_filterdAdvs?.[0]?.id || advisorId);
                            setSelectedTimeslot(null);
                          }}
                        >
                          <img src={Close_Icon_Blue} alt="close" />
                        </IconButton>
                      )}
                  </Box>
                </Card>
              </Grid>
            );
          })}
          {consultant && noKickOffMeeting && (
            <Grid item md={6} xs={12}>
              <Card className="advisorInfoCard">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" alignItems="center" flex={1}>
                    <Avatar
                      className="advisorAvatar"
                      src={consultant?.imageUrl || ''}
                      alt={consultant?.firstName || ''}
                    />
                    <Box>
                      <Typography variant="h5" noWrap>
                        {consultant?.firstName} {consultant?.lastName || ''}
                      </Typography>

                      <Box color={Colors.BLUE_SEVEN}>
                        <Typography variant="body2" noWrap color="inherit">
                          Financial Aid Consultant
                        </Typography>
                      </Box>
                    </Box>
                  </Box>

                  <img src={CAP_LOGO_IMG} alt="logo" width="66px" />
                </Box>
              </Card>
            </Grid>
          )}
        </Grid>
      </Collapse>

      <Collapse in={userHasMoreMentors && selectedAdvisors?.length <= 1}>
        <Box pt={3}>
          <Button
            variant="text"
            color="primary"
            onClick={() => setAllowAdvisorSelection(!allowAdvisorSelection)}
          >
            <img src={Add_Advisor_Img} alt="" />

            {`ADD ${!!selectedAdvisors.length ? 'ANOTHER' : ''} ADVISOR`}
          </Button>
        </Box>
      </Collapse>

      <Collapse in={allowAdvisorSelection && selectedAdvisors?.length <= 1}>
        <Box pt={3}>
          <Grid className={classes.advisorCard} container spacing={2}>
            {allStudentMentors
              ?.filter(
                (item) =>
                  !Object.keys(
                    selectedAdvisors?.find(
                      (mntr) => mntr?.id === item?.mentor?.id
                    ) || {}
                  )?.length
              )
              ?.map((adv, index) => {
                return (
                  <Grid item md={6} xs={12} key={adv?.mentor?.id || index}>
                    <Card
                      className="advisorInfoCard"
                      onClick={() => {
                        const _mentor = selectedAdvisors?.find(
                          (x) => x?.id === adv?.mentor?.id
                        );
                        if (!_mentor || !Object?.keys(_mentor)?.length) {
                          const _selectedAvds = [...selectedAdvisors];
                          if (adv?.mentor) {
                            _selectedAvds.push(adv?.mentor);
                          }
                          handleClearIntervalAndTimer();
                          setSelectedAdvisors([..._selectedAvds]);
                          setMainAdvisor(_selectedAvds?.[0]?.id || advisorId);
                          setSelectedTimeslot(null);
                        }
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Box display="flex" alignItems="center">
                          {adv?.mentor?.pictureUrl ? (
                            <Avatar
                              className="advisorAvatar"
                              src={adv?.mentor?.pictureUrl}
                            />
                          ) : (
                            <Avatar className="advisorAvatar" src={''} />
                          )}
                          <Box>
                            <Typography variant="h5">
                              {adv?.mentor?.firstName} {adv?.mentor?.lastName}
                            </Typography>

                            <Box color={Colors.BLUE_SEVEN}>
                              <Typography
                                variant="body2"
                                noWrap
                                color="inherit"
                              >
                                {adv?.mentor?.advisorLevel}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>

                        <IconButton aria-label="add" className="advisorButton">
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </Collapse>
    </Box>
  );
};
export default AdvisorsSelection;
