// imports
import _ from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Button,
  Dialog,
  InputBase,
  IconButton,
  Typography,
  DialogActions,
  DialogContent,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import Select from 'react-select';
import momentTz from 'moment-timezone';
import DateFnsUtils from '@date-io/date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  ChangeEvent,
  FC,
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// components
import Loader from '../../../../components/Loader';
import AdvisorsSelection from './AdvisorsSelection';
import SnackbarComponent from '../../../../common/SnackBar';
// constants, interfaces
import client from '../../../../apollo';
import { AppContext } from '../../../../contexts';

import {
  BOOK_TIMESLOT,
  MENTOR_FUTURE_AVAILABILITY,
  FETCH_ADVISOR_AVAILABILITY_TIMESLOTS,
  SEND_ADVISOR_EMAIL_FOR_ZERO_AVAILABILITY,
} from './gql';
import {
  AdvisorAvailableTimeslots,
  AdvisorAvailableTimeslots_advisorAvailableTimeslots_time_slots,
} from './gql/__generated__/AdvisorAvailableTimeslots';
import {
  studentMentorAvailability,
  studentMentorAvailability_studentMentorAvailability,
} from './gql/__generated__/studentMentorAvailability';
import { convertToUserSpecificTimeslots } from './helper';
import { returnRequestID, sortStudentMentorsByLevel } from '../../../../common/utility';
import { GET_ALL_STUDENT_MENTORS } from '../../../../graphql/queries/studentMentors';
import {
  getAllStudentMentors_allStudentMentors,
  getAllStudentMentors_allStudentMentors_mentor,
} from '../../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import { allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings } from './gql/__generated__/allAdvisorBookingsQuery';
// styles, images
import { useStyles } from './BookingMeetingStyle';
import Close_Icon from '../../../../img/close-dialog.svg';
import PREVIOUS_PAGE_ICON from '../../../../img/back-arrow-icon.svg';
import { TInterestsList } from '../../../../common/interfaces';

import { intakeFormQuestionsForStudent } from '../../../main/intakeForm/Questions/static';
import { ClientStatus } from '../../../../common/constants';
import { BootstrapTooltip } from '../../../../common/BootstrapTooltip';

import { TooltipInfoIcon } from '../../../../common/assets/svgs';
import { CustomTooltip } from '../../../../common/components';

const MEETING_LENGTHS = [
  {
    id: 'minutes-30',
    value: '30',
  },
  {
    id: 'minutes-45',
    value: '45',
  },
  {
    id: 'minutes-60',
    value: '60',
  },
  {
    id: 'minutes-15',
    value: '15',
  },
];
interface Timeslot {
  start_time: number;
  end_time: number;
}

interface Props extends RouteComponentProps {
  updateBookingDetails?: allAdvisorBookingsQuery_allAdvisorBookings_advisorBookings | null;
  openCalendar: boolean;
  setOpenCalendar: Function;
  setOpenMeetingInfo: Function;
  advisorId: number | null;
  setMeetingInfo: Function;
  refetch?: Function;
  intakeWidgetCondition: boolean;
  nonAssociatedAdvisorDetail?: getAllStudentMentors_allStudentMentors_mentor;
  setIsMeetingBooked?: Function;
  setIsTrialMeetingBooked?: Function;
  defaultAdvisorIds: number[];
  setCalendarAdvisorId?: Function;
}

const BookMeetings: FC<Props> = ({
  openCalendar,
  setOpenCalendar,
  setOpenMeetingInfo,
  advisorId,
  setMeetingInfo,
  updateBookingDetails,
  refetch,
  history,
  nonAssociatedAdvisorDetail,
  intakeWidgetCondition,
  setIsMeetingBooked,
  setIsTrialMeetingBooked,
  defaultAdvisorIds,
  setCalendarAdvisorId,
}) => {
  const classes = useStyles();
  const { user, setUser } = useContext(AppContext);
  const searchParam = new URLSearchParams(window.location.search);
  const noKickOffMeeting =
    !user?.initialKickOffDate &&
    (!user?.firstMeetingBookedDate ||
      user?.firstMeetingBookedDate?.endTime > Date.now() / 1000);
  const isCapEnrollee = user?.clientStatus === ClientStatus.CAP;
  const [selectedDay, setSelectedDay] = useState<null | Date>(new Date());
  const [duration, setDuration] = useState(30);
  const [selectedTimeslot, setSelectedTimeslot] = useState<Timeslot | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [isLoadingFirstTime, setIsLoadingFirstTime] = useState(true);
  const [meetingLoading, setMeetingLoading] = useState(false);
  const [selectedAdvisors, setSelectedAdvisors] = useState<
    (getAllStudentMentors_allStudentMentors_mentor | null | undefined)[]
  >([]);
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);
  const [allowAdvisorSelection, setAllowAdvisorSelection] = useState(false);
  const [timeslots, setTimeslots] = useState<
    | (AdvisorAvailableTimeslots_advisorAvailableTimeslots_time_slots | null)[]
    | null
  >([]);
  const [month, setMonth] = useState<Date | null>(null);
  const [daysWithTimeSLots, setDaysWithTimeSlots] = useState<Date[] | null>(
    null
  );
  const [mainAdvisor, setMainAdvisor] = useState(advisorId);
  const [note, setNote] = useState('');
  const [showAdvisors, setShowAdvisors] = useState(true);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [timer, setTimer] = useState(0);
  const [intervalId, setIntervalId] = useState(0);
  const [mentorFutureAvailability, setMentorFutureAvailability] = useState<
    (studentMentorAvailability_studentMentorAvailability | null)[]
  >([]);
  const [timeStamp, setTimeStamp] = useState<number | null>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [userInterest, setUserInterest] = useState<TInterestsList>();

  const [areaOfInterest, setAreaOfInterest] = useState<TInterestsList>();
  const [other, setOther] = useState(false);
  const [otherValue, setOtherValue] = useState('');
  const [primaryAdvisorId, setPrimaryAdvisorId] = useState<number>(0);

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });

      if (data?.allStudentMentors) {
        const { allStudentMentors } = data;
        if (intakeWidgetCondition) {
          const _sortedAdvisors = sortStudentMentorsByLevel(allStudentMentors);
          setAllStudentMentors(_sortedAdvisors);
        } else {
          setAllStudentMentors(data.allStudentMentors);
        }

        //finding primary advisor
        let primaryAdvId = null;
        allStudentMentors.map((value: any) => {
          let item = value.mentor;
          if (value?.isPrimaryAdvisor) {
            primaryAdvId = item?.id;
          }
        });

        if (!primaryAdvId) {
          primaryAdvId = allStudentMentors.filter(
            (value: any) =>
              value.mentor?.advisorLevel !== 'Former Admissions Officer'
          )?.[0]?.mentor?.id;
        }
        setPrimaryAdvisorId(primaryAdvId);
      }
    };
    fetchAllStudentMentors();
  }, []);

  const filterTimeSlot =
    timeslots?.filter((t) => {
      if (
        t?.start &&
        moment(selectedDay).format('MM-DD-YYYY') ===
          moment(t.start * 1000).format('MM-DD-YYYY')
      )
        return true;
    }) || [];

  const handleNotification = (openAlert: boolean, alertMessage: string) => {
    setOpenAlert(openAlert);

    setAlertMessage(alertMessage);
  };
  const sendEmailToAdvisor = async (advisorId: number) => {
    window.clearInterval(intervalId);
    setTimer(0);
    setIntervalId(0);
    await client.mutate({
      mutation: SEND_ADVISOR_EMAIL_FOR_ZERO_AVAILABILITY,
      variables: {
        advisorId: advisorId,
      },
    });
  };

  const handleClose = () => {
    if (searchParam.get('adv1') || searchParam.get('kickOff') === 'true')
      history.replace({ search: '' });
    setOpenCalendar(false);
    setAllowAdvisorSelection(false);
    setSelectedDay(new Date());
    setSelectedTimeslot(null);
    setDaysWithTimeSlots(null);
    setTimeslots(null);
    setMonth(null);
    setDuration(30);
    setShowAdvisors(true);
    setNote('');
    setIsLoadingFirstTime(true);
    setTimer(0);
    setCalendarAdvisorId && setCalendarAdvisorId(null);
  };

  const handleDurationChange = ({
    currentTarget,
  }: ChangeEvent<HTMLInputElement>) => {
    setSelectedTimeslot(null);
    setDuration(+currentTarget.value);
  };

  const checkMentorZeroAvailability = async () => {
    const advIds =
      (allStudentMentors &&
        allStudentMentors?.map((item) => item?.mentor?.id)) ||
      [];
    const { data }: { data: studentMentorAvailability } = await client.query({
      query: MENTOR_FUTURE_AVAILABILITY,
      variables: {
        advisorIds: [...advIds],
      },
    });
    if (data) {
      const { studentMentorAvailability } = data;
      if (studentMentorAvailability && studentMentorAvailability?.length > 0) {
        setMentorFutureAvailability(studentMentorAvailability);
      }
    }
  };

  useEffect(() => {
    if (updateBookingDetails && Object.keys(updateBookingDetails).length) {
      const previousParticipants =
        allStudentMentors
          ?.filter(
            (stdAdv) =>
              stdAdv &&
              Object.keys(
                updateBookingDetails.allParticipants?.find(
                  (x) =>
                    x?.id === stdAdv?.mentor?.id && x?.roles?.includes('Mentor')
                ) || {}
              )?.length
          )
          .map((item) => item?.mentor) || [];
      if (previousParticipants && previousParticipants?.length) {
        setSelectedAdvisors(previousParticipants);
      }
      if (updateBookingDetails.startTime) {
        setSelectedDay(new Date(updateBookingDetails.startTime * 1000));
      }
    } else {
      if (nonAssociatedAdvisorDetail) {
        setSelectedAdvisors([{ ...nonAssociatedAdvisorDetail }]);
        setMainAdvisor(nonAssociatedAdvisorDetail?.id || advisorId);
      } else {
        const advisorClicked =
          allStudentMentors
            ?.filter((stdAdv) => stdAdv?.mentor?.id === advisorId)
            .map((item) => item?.mentor) || [];
        const defaultAdvisors = allStudentMentors?.length
          ? allStudentMentors.map((obj) => obj?.mentor)
          : [];

        const defaultAdvisorsToShow =
          allStudentMentors
            ?.filter((stdAdv) =>
              defaultAdvisorIds.includes(stdAdv?.mentor?.id || -1)
            )
            .map((item) => item?.mentor) || [];

        intakeWidgetCondition
          ? setSelectedAdvisors([...defaultAdvisors])
          : setSelectedAdvisors(
              advisorId ? [...advisorClicked] : [...defaultAdvisorsToShow]
            );

        setMainAdvisor(
          intakeWidgetCondition
            ? defaultAdvisors?.[0]?.id || advisorId
            : advisorClicked?.[0]?.id || advisorId
        );
      }
    }
  }, [openCalendar, allStudentMentors]);

  const goNextMonthIfLastDay = () => {
    const currentDate = month || new Date();
    const lastDayOfMonth = moment().endOf('month').toDate();

    if (currentDate.getDate() === lastDayOfMonth.getDate()) {
      setSelectedDay(moment().add(1, 'days').toDate());
      setMonth(moment().add(1, 'days').toDate());
    }
  };

  const availableDays = () => {
    const getAvailableTimeSLotDays = async () => {
      const ts = returnRequestID();
      try {
        if (
          openCalendar &&
          ((selectedAdvisors.length && isLoadingFirstTime) ||
            !isLoadingFirstTime)
        ) {
          setDaysWithTimeSlots(null);
          setLoading(true);
          setTimeslots(null);
          setTimeStamp(ts);
          let date = new Date(new Date().setDate(2));
          if (month) {
            date = moment(month).add(24, 'hours').toDate();
          }
          const { data }: { data: AdvisorAvailableTimeslots } =
            await client.query({
              query: FETCH_ADVISOR_AVAILABILITY_TIMESLOTS,
              fetchPolicy: 'network-only',
              variables: {
                AdvisorIds: selectedAdvisors.map((x) => x?.id),
                duration: duration,
                date: moment(date).format('MM-DD-YYYY'),
                offset: moment().utcOffset() / 60,
                monthOrDay: 'month',
                studentCurrentTime: Date.now(),
                isUserInDSTtimeZone: moment([2011, 2, 14]).isDST(),
              },
            });

          let shouldUpdate = true;

          setTimeStamp((prev: number | null) => {
            if (ts !== prev) {
              shouldUpdate = false;
            }
            return prev;
          });

          if (shouldUpdate) {
            if (data.advisorAvailableTimeslots?.time_slots) {
              const days = convertToUserSpecificTimeslots(
                _.cloneDeep(data.advisorAvailableTimeslots?.time_slots)
              );
              setDaysWithTimeSlots(days);
              if (days.length) setSelectedDay(days[0]);
              setTimeslots(data?.advisorAvailableTimeslots?.time_slots);
            }
            isLoadingFirstTime && setIsLoadingFirstTime(false);
          }
        }
      } catch (error) {
        console.log('Error while fetching getAvailableTimeSLotDays:', error);
      } finally {
        let shouldUpdate = true;

        setTimeStamp((prev: number | null) => {
          if (ts !== prev) {
            shouldUpdate = false;
          }
          return prev;
        });
        if (shouldUpdate) {
          setLoading(false);
        }
      }
    };
    getAvailableTimeSLotDays();
  };
  const handleClearIntervalAndTimer = () => {
    window.clearInterval(intervalId);
    setTimer(0);
    setIntervalId(0);
  };
  useEffect(availableDays, [month, duration, selectedAdvisors]);
  useEffect(() => {
    if (selectedAdvisors?.length === 1) {
      if (mentorFutureAvailability?.length > 0 && openCalendar) {
        const check = mentorFutureAvailability.find(
          (item) =>
            item?.advisorId === selectedAdvisors[0]?.id &&
            item?.nextAvailabilty === false
        );
        if (check) {
          const id = window.setInterval(() => {
            setTimer((prevValue) => prevValue + 1);
          }, 1000);

          setIntervalId(id);
        }
      }
    }
    return () => {
      window.clearInterval(intervalId);
    };
  }, [mentorFutureAvailability, selectedAdvisors]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const defaultAdvisors = allStudentMentors?.length
        ? allStudentMentors?.map((obj) => obj?.mentor)
        : [];
      intakeWidgetCondition && setSelectedAdvisors(defaultAdvisors);

      checkMentorZeroAvailability();
      const advisorIds: number[] = [];
      searchParam.forEach(function (value, key) {
        if (key.includes('adv')) {
          advisorIds.push(+value);
        }
      });
      if (advisorIds.length) {
        const filtereMentors =
          allStudentMentors
            ?.filter((item) => {
              if (item?.mentor?.id)
                return advisorIds.includes(item?.mentor?.id);
            })
            .map((m) => m?.mentor) || [];
        setMainAdvisor(advisorIds[0]);

        const selectedAdvs = [...filtereMentors];
        if (nonAssociatedAdvisorDetail) {
          if (nonAssociatedAdvisorDetail?.id === advisorIds[0])
            selectedAdvs.unshift(nonAssociatedAdvisorDetail);
          else selectedAdvs.push(nonAssociatedAdvisorDetail);
        }

        setSelectedAdvisors(selectedAdvs);
      }
    }

    return () => {
      isMounted = false;
      window.clearInterval(intervalId);
    };
  }, [allStudentMentors]);

  const consultant = isCapEnrollee ? allStudentMentors?.[0]?.consultant : null;

  const handleConfirm = async () => {
    try {
      setMeetingLoading(true);

      const participants = selectedAdvisors.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        email: item?.email || '',
        id: item?.id,
        role: 'Mentor',
      }));
      const _selectedAvdsWithAvatar = selectedAdvisors.map((item) => ({
        name: `${item?.firstName} ${item?.lastName}`,
        email: item?.email || '',
        id: item?.id,
        avatar: item?.pictureUrl,
      }));

      const { data } = await client.mutate({
        mutation: BOOK_TIMESLOT,
        variables: {
          AdvisorId: mainAdvisor ? mainAdvisor : defaultAdvisorIds[0],
          start_time: selectedTimeslot?.start_time,
          end_time: selectedTimeslot?.end_time,
          note: note || null,
          participants: [
            ...participants,
            {
              name: `${user?.firstName} ${user?.lastName}`,
              email: user?.email,
              id: user?.id,
              role: 'Student',
            },
          ],
          isTrial: isTrial,
          areasOfInterest: userInterest?.length
            ? otherValue
              ? [
                  ...userInterest
                    .map((interest) => interest.label)
                    .filter((interest) => interest !== 'other'),
                  otherValue,
                ]
              : userInterest.map((interest) => interest.label)
            : [],
          consultantId: consultant?.id || null,
        },
      });

      if (setIsTrialMeetingBooked) setIsTrialMeetingBooked(true);

      handleClose();
      if (!data?.createNylasCalendarEvent?.success) {
        handleNotification(
          true,
          data?.createNylasCalendarEvent?.message ||
            'Something went wrong while creating meeting!'
        );
      } else {
        setMeetingInfo({
          start_time: selectedTimeslot?.start_time,
          end_time: selectedTimeslot?.end_time,
          advisors: _selectedAvdsWithAvatar,
          studentName: `${user?.firstName} ${user?.lastName}`,
          note: note || 'N/A',
        });
        setOpenMeetingInfo(true);
      }
    } catch (error: any) {
      handleNotification(true, 'Something went wrong while creating meeting!');
      handleClose();
    } finally {
      setMeetingLoading(false);
    }
  };
  if (timer === 8 && selectedAdvisors.length === 1) {
    const check = mentorFutureAvailability.find(
      (item) =>
        item?.advisorId === selectedAdvisors[0]?.id &&
        item?.nextAvailabilty === false
    );

    if (check && check?.advisorId) {
      sendEmailToAdvisor(check?.advisorId);
    }
  }

  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const handleInterestChange = (data: any) => {
    const other = data?.find((item: any) => item.label === 'other');
    if (other) {
      setOther(true);
    } else {
      setOther(false);
      setOtherValue('');
    }

    setUserInterest(data);
  };

  const GetUserInterests = async () => {
    if (intakeFormQuestionsForStudent) {
      const questions: any = intakeFormQuestionsForStudent.find((data: any) => {
        return data.step === 5;
      });

      const areas = questions.data[0].options.map((interest: string) => {
        return {
          label: interest,
          value: interest,
        };
      });

      setAreaOfInterest(areas);
    }
  };

  useEffect(() => {
    if ((user?.SmartMatchId || isCapEnrollee) && noKickOffMeeting) {
      GetUserInterests();
    }
  }, []);

  useEffect(() => {
    if (!daysWithTimeSLots?.length && !loading) goNextMonthIfLastDay();
  }, [timeslots, daysWithTimeSLots]);

  useEffect(() => {
    if (intakeWidgetCondition) {
      setSelectedTimeslot(null);
      setDuration(45);
    }
  }, [intakeWidgetCondition, timeslots, daysWithTimeSLots]);

  const handleOverlappingAvailablity = () => {
    const primaryAdvisor =
      allStudentMentors
        ?.filter((item) => item?.mentor?.id === primaryAdvisorId)
        .map((item) => item?.mentor) || [];

    setSelectedAdvisors(primaryAdvisor);
    setMainAdvisor(primaryAdvisorId);
  };

  return (
    <>
      <SnackbarComponent
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openAlert}
        autoHideDuration={5000}
        handleClose={() => setOpenAlert(false)}
        message={alertMessage}
        severity="error"
      />

      <Dialog open={openCalendar} onClose={handleClose} maxWidth="md">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={classes.dialogPaperHeader}
        >
          <Typography variant="h4">
            {intakeWidgetCondition
              ? 'Schedule Orientation Call'
              : updateBookingDetails &&
                Object.keys(updateBookingDetails)?.length
              ? 'Reschedule Meeting'
              : 'Book Meeting'}
          </Typography>

          <IconButton aria-label="close" onClick={handleClose}>
            <img src={Close_Icon} alt="close" />
          </IconButton>
        </Box>

        <DialogContent>
          {showAdvisors ? (
            <>
              <AdvisorsSelection
                advisorId={advisorId}
                allowAdvisorSelection={allowAdvisorSelection}
                note={note}
                setNote={setNote}
                selectedAdvisors={selectedAdvisors}
                setSelectedAdvisors={setSelectedAdvisors}
                setMainAdvisor={setMainAdvisor}
                setSelectedTimeslot={setSelectedTimeslot}
                setAllowAdvisorSelection={setAllowAdvisorSelection}
                handleClearIntervalAndTimer={handleClearIntervalAndTimer}
                intakeWidgetCondition={intakeWidgetCondition}
                allStudentMentors={allStudentMentors}
                noKickOffMeeting={noKickOffMeeting}
              />
              <Grid className={classes.meetingRadioContainer}>
                <Box pb={3}>
                  <Typography variant="h5">Length of Meeting</Typography>
                </Box>

                {isTrial ? (
                  <Grid className={classes.radioGroup}>
                    <Grid className={classes.radioGroupGrid}>
                      <input
                        type="radio"
                        name="duration"
                        defaultChecked
                        onChange={handleDurationChange}
                        id={'minutes-30'}
                        value={30}
                      />
                      <label htmlFor={'minutes-30'}>25 minutes</label>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid className={classes.radioGroup}>
                    {MEETING_LENGTHS.map((item) => {
                      const isDisaled = noKickOffMeeting && item.value === '15';
                      if (intakeWidgetCondition && item.value !== '45')
                        return <></>;
                      return (
                        <Grid className={classes.radioGroupGrid} key={item.id}>
                          <input
                            disabled={isDisaled}
                            type="radio"
                            name="duration"
                            defaultChecked={intakeWidgetCondition ? true : duration == parseInt(item.value)}
                            onChange={handleDurationChange}
                            id={item.id}
                            value={item.value}
                          />
                          <label
                            htmlFor={item.id}
                            className={isDisaled ? classes.disabledLabel : ''}
                          >
                            {item.value} minutes
                          </label>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>

              <Box className={classes.datePickerWrapper}>
                {isLoadingFirstTime ? (
                  <Loader
                    isSmallLoader={true}
                    loaderText="Loading availabilities…"
                  />
                ) : (
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={12} className="MuiDatePicker">
                      <Box pb={2.5}>
                        <Typography variant="h5">
                          Select Date and Time Slot
                        </Typography>
                      </Box>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          autoOk
                          disablePast
                          shouldDisableDate={(x) => {
                            if (
                              moment(x).format('MM-DD-YYYY') ===
                              moment(new Date()).format('MM-DD-YYYY')
                            ) {
                              return true;
                            }
                            const _daysWithTimeSlots =
                              !!daysWithTimeSLots?.length
                                ? daysWithTimeSLots?.map((v) =>
                                    moment(v).format('MM-DD-YYYY')
                                  )
                                : [];

                            if (
                              x &&
                              x.getMonth() ==
                                new Date(month || new Date()).getMonth()
                            ) {
                              if (
                                _daysWithTimeSlots.includes(
                                  moment(x).format('MM-DD-YYYY')
                                )
                              ) {
                                return false;
                              } else {
                                return true;
                              }
                            } else {
                              return true;
                            }
                          }}
                          fullWidth
                          variant="static"
                          openTo="date"
                          value={selectedDay}
                          onMonthChange={(e) => setMonth(e)}
                          disableToolbar={true}
                          onChange={(e) => {
                            if (e) setSelectedDay(e);
                            setSelectedTimeslot(null);
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid
                      className={classes.availableTimeSlots}
                      item
                      md={6}
                      xs={12}
                    >
                      <Typography variant="h5">Available Time Slots</Typography>
                      <Typography variant="caption">
                        All Times in{' '}
                        {momentTz.tz(momentTz.tz.guess()).format('z')}
                      </Typography>

                      <Box className="availableTimeScroll">
                        <Grid
                          className={clsx(
                            classes.radioGroup,
                            classes.radioNotFlex
                          )}
                        >
                          {loading ? (
                            <Box
                              display="flex"
                              height="100%"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <CircularProgress color="secondary" size={24} />
                            </Box>
                          ) : !!timeslots?.length && !!filterTimeSlot.length ? (
                            filterTimeSlot?.map((timeslot) => {
                              return (
                                <Grid
                                  key={timeslot?.start}
                                  className={clsx(
                                    classes.radioGroupGrid,
                                    classes.radioGroupSlots
                                  )}
                                >
                                  <input
                                    type="radio"
                                    name="selectedDuration"
                                    id={`radio-${timeslot?.start}`}
                                    value={timeslot?.start}
                                    checked={
                                      timeslot?.start ==
                                        selectedTimeslot?.start_time &&
                                      timeslot?.end ===
                                        selectedTimeslot?.end_time
                                    }
                                    onChange={({
                                      currentTarget,
                                    }: ChangeEvent<HTMLInputElement>) => {
                                      if (timeslot?.start && timeslot?.end) {
                                        setSelectedTimeslot({
                                          start_time: timeslot?.start,
                                          end_time: timeslot?.end,
                                        });
                                      }
                                    }}
                                  />
                                  <label htmlFor={`radio-${timeslot?.start}`}>
                                    {timeslot?.start
                                      ? moment
                                          .unix(timeslot?.start)
                                          .format('LT')
                                      : '--'}
                                  </label>
                                </Grid>
                              );
                            })
                          ) : selectedAdvisors.length > 1 ? (
                            <Box className={classes.noTimeSlots}>
                              <Box textAlign="center">
                                <Typography variant="body2">
                                  No time slots available that work for all
                                  advisors selected.
                                  <CustomTooltip
                                    title={
                                      'Advisors can work with you to arrange recurring meetings that work with your schedule. Orientation calls can be completed with just your Primary Advisor.  '
                                    }
                                  >
                                    <IconButton className="infoIcon">
                                      <TooltipInfoIcon />
                                    </IconButton>
                                  </CustomTooltip>
                                </Typography>

                                <span
                                  onClick={() => {
                                    handleOverlappingAvailablity();
                                  }}
                                  className="bookPrimaryAdvisor"
                                >
                                  Book with Primary Advisor only
                                </span>
                              </Box>
                            </Box>
                          ) : (
                            <Box
                              display="flex"
                              height="100%"
                              flexDirection="column"
                              alignItems="center"
                              justifyContent="center"
                            >
                              No time slot found.
                            </Box>
                          )}
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </>
          ) : (
            <Box className={classes.addAdvisor}>
              <IconButton
                className="backButton"
                onClick={() => setShowAdvisors(true)}
              >
                <img src={PREVIOUS_PAGE_ICON} alt="back" />
              </IconButton>

              <Grid container className={classes.meetingNoteArea}>
                {isCapEnrollee && noKickOffMeeting && (
                  <Box mb={4} width="100%">
                    <Typography variant="h5" className="dialogHeading">
                      In Which of the Following Areas are you Interested in
                      Getting Assistance?
                    </Typography>
                    <Typography variant="caption">
                      Select all that apply
                    </Typography>

                    <Select
                      name="areas"
                      isMulti={true}
                      isClearable={true}
                      isSearchable={true}
                      placeholder="Required"
                      onMenuOpen={onMenuOpen}
                      onMenuClose={onMenuClose}
                      classNamePrefix="select"
                      className=""
                      defaultValue={userInterest}
                      options={areaOfInterest}
                      onChange={handleInterestChange}
                      isDisabled={meetingLoading}
                    />
                    {other && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Optional"
                        value={otherValue}
                        onChange={(event) => {
                          setOtherValue(event.target.value);
                        }}
                        disabled={meetingLoading}
                      />
                    )}
                  </Box>
                )}

                <Box width="100%">
                  <Typography variant="h5" className="dialogHeading">
                    Is there anything else you'd like to discuss during the
                    meeting?
                  </Typography>
                  <InputBase
                    placeholder="Optional"
                    multiline
                    value={note}
                    rows={4}
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                  />
                </Box>
              </Grid>
            </Box>
          )}
        </DialogContent>

        <DialogActions
          className={
            !showAdvisors
              ? classes.dialogActionText
              : classes.advisorScreenBottom
          }
        >
          {showAdvisors ? (
            <Button
              onClick={() => {
                isTrial ? handleConfirm() : setShowAdvisors(false);
              }}
              color={isTrial ? 'secondary' : 'primary'}
              variant="contained"
              disabled={
                !selectedTimeslot ||
                !Object.keys(selectedTimeslot)?.length ||
                !selectedAdvisors ||
                !selectedAdvisors?.length ||
                meetingLoading
              }
            >
              {isTrial ? 'Confirm Meeting' : 'Next'}
            </Button>
          ) : (
            <>
              <Box className="advisorTextGrid">
                <BootstrapTooltip
                  placement="top"
                  title={
                    <Fragment>
                      {' '}
                      Meeting with{' '}
                      {selectedAdvisors
                        .map(
                          (adv) =>
                            `${adv?.firstName || ''} ${adv?.lastName || ''}`
                        )
                        .join(' and ')}
                      {consultant && noKickOffMeeting
                        ? ` and ${consultant.firstName} ${
                            consultant?.lastName || ''
                          }`
                        : ''}
                    </Fragment>
                  }
                >
                  <Typography variant="h5" className="headingText" noWrap>
                    Meeting with{' '}
                    {selectedAdvisors
                      .map(
                        (adv) =>
                          `${adv?.firstName || ''} ${adv?.lastName || ''}`
                      )
                      .join(' and ')}
                    {consultant && noKickOffMeeting
                      ? ` and ${consultant.firstName} ${
                          consultant?.lastName || ''
                        }`
                      : ''}
                  </Typography>
                </BootstrapTooltip>
                <Typography variant="body1" className="subtitleText" noWrap>
                  {selectedTimeslot?.start_time && selectedTimeslot?.end_time
                    ? `${moment(selectedTimeslot.start_time * 1000).format(
                        'ddd MMMM DD, YYYY, h:mm'
                      )} -
                      ${moment(selectedTimeslot.end_time * 1000).format('LT')}

                      ${momentTz(selectedTimeslot.start_time * 1000)
                        .tz(momentTz.tz.guess())
                        .format('z')}`
                    : 'N/A'}
                </Typography>
              </Box>
              <Button
                onClick={() => {
                  handleConfirm();
                }}
                color="primary"
                variant="contained"
                disabled={
                  isCapEnrollee &&
                  noKickOffMeeting &&
                  !userInterest?.length
                    ? true
                    : !selectedTimeslot ||
                      !Object.keys(selectedTimeslot)?.length ||
                      !selectedAdvisors ||
                      !selectedAdvisors?.length ||
                      meetingLoading
                }
              >
                {updateBookingDetails &&
                Object.keys(updateBookingDetails)?.length
                  ? 'Update Meeting'
                  : 'Confirm Meeting'}
                {meetingLoading && (
                  <CircularProgress size={24} className="buttonProgress" />
                )}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
export default withRouter(BookMeetings);
