import gql from 'graphql-tag';

export const SMART_MATCH = gql`
  query SmartMatch($userToken: String, $packageId: Int!) {
    smartMatch(userToken: $userToken, packageId: $packageId) {
      id
      UserId
      AdvisorId
      FAOId
      CollegeIds
      AreaOfInterestsIds
      TimeZoneId
      planId
      isActive
      advisor {
        id
        firstName
        lastName
        pictureUrl
        userMajors {
          id
          educationType
          degreeType
          MajorId
          majorName
        }
        currentCollege {
          name
          logo
          styleConfig {
            bgDark
          }
        }
        graduateSchool {
          name
          location
          logo
          styleConfig {
            bgDark
          }
        }
        graduateSchoolGradYear
        Theme
      }
      fao {
        id
        Theme
        firstName
        lastName
        pictureUrl
        userMajors {
          id
          educationType
          degreeType
          MajorId
          majorName
        }
        currentCollege {
          name
          logo
          styleConfig {
            bgDark
          }
        }
        graduateSchool {
          logo
          name
          location
        }
        graduateSchoolGradYear
        previouslyWorkedColleges {
          Title
          college {
            id
            name
          }
        }
      }
      colleges {
        label
        value
      }
    }
  }
`;

export const AREAS_OF_INTEREST_QUERY = gql`
  query GetAreasOfInterestQuery {
    getAreasOfInterest {
      id
      displayName
      shortName
      rank
      hasAdvisor
    }
  }
`;

export const ALL_HIGH_SCHOOLS_QUERY = gql`
  query GetAllHighSchoolsQuery($searchQuery: String) {
    getHighSchools(input: { searchQuery: $searchQuery }) {
      highSchools {
        id
        high_school_name
      }
    }
  }
`;

export const TIME_ZONES_QUERY = gql`
  query GetTimeZonesQuery {
    getTimezones {
      id
      timezoneLabel
      timezoneValue
    }
  }
`;

export const GET_SMART_MATCH_ADVISORS_QUERY = gql`
  query GetSmartMatchAdvisorQuery(
    $studentId: Int!
    $collegeIds: [Int]
    $majorCategoryIds: [Int]
    $internationExp: Boolean
    $available: Boolean
    $FAO: Boolean
    $highSchoolYear: String
    $timezone: String
    $AdmissionPackageId: Int
    $TimeZoneId: Int
  ) {
    getSmartMatchAdvisors(
      input: {
        studentId: $studentId
        collegeIds: $collegeIds
        majorCategoryIds: $majorCategoryIds
        internationExp: $internationExp
        available: $available
        FAO: $FAO
        highSchoolYear: $highSchoolYear
        timezone: $timezone
        AdmissionPackageId: $AdmissionPackageId
        TimeZoneId: $TimeZoneId
      }
    ) {
      advisor {
        id
        firstName
        lastName
        pictureUrl
        currentCollege {
          name
          logo
          styleConfig {
            bgDark
          }
        }
        graduateSchool {
          logo
          name
          location
          styleConfig {
            bgDark
          }
        }
        userMajors {
          id
          educationType
          degreeType
          MajorId
          majorName
          MajorCategoryId
        }
        intakeYear
        graduateSchoolGradYear
        Theme
        previouslyWorkedColleges {
          Title
          college {
            id
            name
          }
        }
        tags {
          name
        }
      }
      collegeAttend
      collegeAssist
      interestNames
      collegeAttendNames
      collegeAssistNames
      otherCollegeAssistNames
      score
      shortBio
    }
  }
`;

export const CREATE_SMART_MATCH_MUTATION = gql`
  mutation CreateSmartMatchMutation(
    $userToken: String
    $AdvisorId: Int
    $FAOId: Int
    $CollegeIds: [Int]
    $AreaOfInterestsIds: [Int]
    $TimeZoneId: Int
    $Gender: Int
    $Language: Int
    $packageId: Int!
    $step: Int!
    $skip: Boolean
  ) {
    createSmartMatch(
      input: {
        userToken: $userToken
        AdvisorId: $AdvisorId
        FAOId: $FAOId
        CollegeIds: $CollegeIds
        AreaOfInterestsIds: $AreaOfInterestsIds
        TimeZoneId: $TimeZoneId
        Gender: $Gender
        Language: $Language
        packageId: $packageId
        step: $step
        skip: $skip
      }
    ) {
      success
      message
    }
  }
`;

export const ALL_COLLEGES_QUERY = gql`
  query AllCollegeQuery_SmartMatch(
    $limit: Int
    $page: Int
    $searchQuery: String
  ) {
    allColleges(
      input: { limit: $limit, page: $page, searchQuery: $searchQuery }
    ) {
      colleges {
        id
        name
        logo
      }
    }
  }
`;

export const SMART_MATCH_BANNER_QUERY = gql`
  query SmartMatchBannerQuery {
    smartMatchBanner {
      success
      message
    }
  }
`;

export const SMART_MATCH_ADVISOR_DETAIL = gql`
  query smartMatchAdvisorDetail(
    $userToken: String
    $packageId: Int!
    $id: Int
  ) {
    smartMatch(userToken: $userToken, packageId: $packageId) {
      advisorDetail(id: $id) {
        advisor {
          id
          firstName
          lastName
          pictureUrl
          currentCollege {
            name
            logo
            styleConfig {
              bgDark
            }
          }
          graduateSchool {
            logo
            name
            location
            styleConfig {
              bgDark
            }
          }
          userMajors {
            id
            educationType
            degreeType
            MajorId
            majorName
            MajorCategoryId
          }
          intakeYear
          graduateSchoolGradYear
          Theme
          previouslyWorkedColleges {
            Title
            college {
              id
              name
            }
          }
          tags {
            name
          }
          advisorLevel
        }
        interestNames
        collegeAttendNames
        collegeAssistNames
      }
    }
  }
`;

export const GET_ADVISORS_AVAILABILITY_QUERY = gql`
  query GetAdvisorsAvailabilityQuery(
    $advisorId: Int
    $AdmissionPackageId: Int
    $highSchoolYear: String
    $TimeZoneId: Int
    $role: String
  ) {
    getAdvisorsAvailability(
      input: {
        advisorId: $advisorId
        AdmissionPackageId: $AdmissionPackageId
        highSchoolYear: $highSchoolYear
        TimeZoneId: $TimeZoneId
        role: $role
      }
    ) {
      advisorId
      available
    }
  }
`;

export const GET_SMART_MATCH_STATUS = gql`
  query getSmartMatchStatus($studentId: Int) {
    getSmartMatchStatus(studentId: $studentId) {
      isActive
    }
  }
`;
