import { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import moment from 'moment';
import { Query } from 'react-apollo';

import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Hidden,
  IconButton,
  Typography,
} from '@material-ui/core';

import TimeZoneDialog from '../../../components/TimeZoneDialog';
import BookingUnavailableDialog from './BookingUnavailableDialog';
import MeetingBooked, { meetingInfo } from './BookingMeeting/MeetingBooked';

import GetStartCard from '../intakeForm/GetStartCard';
import ApplicationsCard from './ApplicationsCard';
import BlogPostsCard from './BlogPostCards';
import BookMeetings from './BookingMeeting/BookMeetings';
import MeetingsCard from './MeetingsCard';
import PackageCard from './PackageCard';
import PackageCardMobile from './PackageCardMobile';
import PreMeeting from './PreMeeting';
import UpgradeCard from './UpgradeCard';

import client from '../../../apollo';

import {
  ClientStatus,
  meetingStages,
  meetingStatuses,
  tealiumConstants,
} from '../../../common/constants';
import { MEETING_SET, REDIRECT_TO_LOGIN, USER_TOKEN } from '../../../constants';
import { AppContext } from '../../../contexts';
import { GET_MEETING_TIME } from '../../../graphql/queries/preMeetingBanner';

import DRIVE_ICON from '../../../img/drive-icon.png';
import EDIT_IMAGE from '../../../img/edit-pencil.svg';
import NEXT_ARROW_ICON from '../../../img/light-arrow-next.svg';
import OPP_BG_IMG from '../../../img/opp-database-img.png';
import PHONE_UPGRADE_IMAGE from '../../../img/phone-white.svg';
import WEBINAR_BG_IMAGE from '../../../img/webinar-bg-img.png';
import BG_LAYER_IMAGE from '../../../img/webinar-card-bg-layer.png';

import FooterNew from '../../../components/layout/FooterNew';
import GRADUATE_CAP_ICON from '../../../img/graduated-icon-cap.svg';
import HomePageCalendlyModel from '../../DigitalSet/HomePageModel';
import { allAdvisorBookingsQuery } from '../home/BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';
import { getMeetingTime } from '../../../graphql/queries/preMeetingBanner/__generated__/getMeetingTime';
import { CURRENT_USER_QUERY } from '../layouts/MainLayout';
import { UserQuery } from '../layouts/__generated__/UserQuery';
import {
  getAllStudentMentors_allStudentMentors,
  getAllStudentMentors_allStudentMentors_mentor,
} from '../../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import {
  ADD_MENTOR_REVIEW_RATING,
  ALL_ADVISOR_BOOKINGS,
} from './BookingMeeting/gql';
import VideoChatHoursUsedAlert from './VideoChatHoursUsedAlert';

import { cjConfig, createTealiumCall } from '../../../common/utility';
import { MeetingRatingFeedbackDialog } from './BookingMeeting/MeetingRating';
import VideoModal from './StudentFirstTimeLoginVideoModal';

import Loader from '../../../components/Loader';
import { CHECK_STUDENT_PROFILE } from '../account/gql';
import MeetingForTrial from '../intakeForm/MeetingForTrial';

import { ExtraOpportunitiesCard } from './ExtraOpportunitiesCard';
import SimpleCard from './SimpleCard';
import { WebinarRouteCard } from './WebinarRouteCard';
import { homePageStyles } from './styles';
import UpdateProfile from '../../../components/smartMatch/UpdateProfile';
import { SMART_MATCH_BANNER_QUERY } from '../../../graphql/queries/smartMatch';
import { SmartMatchBannerQuery } from '../../../graphql/queries/smartMatch/__generated__/SmartMatchBannerQuery';
import { GET_WEBINARS } from '../../webinar/gql';
import { getUserUpcomingAndPastWebinarsQuery } from '../../webinar/gql/__generated__/getUserUpcomingAndPastWebinarsQuery';
import { GET_ALL_STUDENT_MENTORS } from '../../../graphql/queries/studentMentors';
import VccsWidgetCard from './VccsWidgetCard';
// import VideoChatHoursUsedAlert from './VideoChatHoursUsedAlert';

export const AdvisorBookingModal = ({
  openCalendar,
  setOpenCalendar,
  advisorId,
  refetch,
  nonAssociatedAdvisorDetail,
  intakeWidgetCondition = false,
  setIsMeetingBooked,
  setIsTrialMeetingBooked,
  defaultAdvisorIds,
  setAdvisorId,
}: {
  openCalendar: boolean;
  setOpenCalendar: Function;
  refetch?: Function;
  advisorId: number | null;
  nonAssociatedAdvisorDetail?: getAllStudentMentors_allStudentMentors_mentor;
  intakeWidgetCondition?: boolean;
  setIsMeetingBooked?: Function;
  setIsTrialMeetingBooked?: Function;
  defaultAdvisorIds?: number[];
  setAdvisorId?: Function;
}) => {
  const [openMeetingInfo, setOpenMeetingInfo] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState<meetingInfo>({
    start_time: 0,
    end_time: 0,
    advisors: [],
    studentName: '',
    note: '',
  });
  const mountEffect = () => {
    if (openMeetingInfo) refetch && refetch();
  };
  useEffect(mountEffect, [openMeetingInfo]);

  return (
    <>
      <BookMeetings
        openCalendar={openCalendar}
        setOpenCalendar={setOpenCalendar}
        setOpenMeetingInfo={setOpenMeetingInfo}
        setMeetingInfo={setMeetingInfo}
        advisorId={advisorId}
        defaultAdvisorIds={defaultAdvisorIds || []}
        nonAssociatedAdvisorDetail={nonAssociatedAdvisorDetail}
        intakeWidgetCondition={intakeWidgetCondition}
        setIsMeetingBooked={setIsMeetingBooked}
        setIsTrialMeetingBooked={setIsTrialMeetingBooked}
        setCalendarAdvisorId={setAdvisorId}
      />

      <MeetingBooked
        openMeetingInfo={openMeetingInfo}
        setOpenMeetingInfo={setOpenMeetingInfo}
        meetingInfo={meetingInfo}
      />
    </>
  );
};

const StudentHomePage = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const { user, setUser } = useContext(AppContext);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [advisorId, setAdvisorId] = useState<number | null>(null);
  const [defaultAdvisorIds, setDefaultAdvisorIds] = useState<number[]>([]);
  const [isMeetingBooked, setIsMeetingBooked] = useState(false);
  const [isTrialMeetingBooked, setIsTrialMeetingBooked] = useState(false);
  const [isNoAdvisorMatch, setIsNoAdvisorMatch] = useState(false);
  const [isPreMeeting, setIsPreMeeting] = useState(false);
  const allowedPremiumPackageTypesForOtherAdvisors = [
    'Elite',
    'Platinum',
    'Ivy Plus',
    'Diamond',
    'Custom',
    'Scholars',
  ];
  const [openStudentMentorRatingDialog, setOpenStudentMentorRatingDialog] =
    useState(false);
  const [meetingRatingData, setMeetingRatingData] = useState<{
    mentorIds: number[];
    meetingId?: number | null;
    meetingtime?: string;
    advisorFirstName: string;
    mentorPicture: string;
  }>({
    mentorIds: [],
    meetingId: null,
    advisorFirstName: '',
    mentorPicture: '',
    meetingtime: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const { setShowCollegeFilter } = useContext(AppContext);
  const isCanceled =
    user?.clientStatus === ClientStatus.BAD_DEBT ||
    user?.clientStatus === ClientStatus.CANCELED;
  const isCapEnrollee = user?.clientStatus === ClientStatus.CAP;
  const isPremiumUser = user?.isPremium;
  const year = user?.highSchoolYear;
  const [isAllStudentMentorsFetched, setIsAllStudentMentorsFetched] =
    useState(false);
  const classes = homePageStyles();
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const isAllVideoChatHoursUsed =
    (user?.premiumVideoCountUsed || 0) >= (user?.premiumTotalVideoCount || 0);
  const isTrial =
    user?.studentTrial?.trialStartedAt && !user?.studentTrial?.trialCompletedAt
      ? true
      : false;

  const packageCardCondition =
    (isPremiumUser &&
      (user?.initialKickOffDate ||
        (user?.firstMeetingBookedDate &&
          user.firstMeetingBookedDate?.endTime < Date.now() / 1000))) ||
    false;

  const intakeWidgetCondition =
    isPremiumUser &&
    !user?.initialKickOffDate &&
    (!user?.firstMeetingBookedDate ||
      user?.firstMeetingBookedDate?.endTime > Date.now() / 1000);
  const firstTimeLoggedIn =
    localStorage.getItem('firstTimeLogin') === 'true' ? true : false;

  const mountEffect = () => {
    const fetchAllStudentMentors = async () => {
      try {
        const { data } = await client.query({
          query: GET_ALL_STUDENT_MENTORS,
          fetchPolicy: 'network-only',
        });
        if (data && data.allStudentMentors) {
          setAllStudentMentors(data.allStudentMentors);
        }
        setIsAllStudentMentorsFetched(true);
      } catch (ex) {
        console.log('error while fetching student` mentors:::', ex);
      }
    };
    fetchAllStudentMentors();
    const userQuery = async () => {
      try {
        const { data }: { data: UserQuery } = await client.query({
          query: CURRENT_USER_QUERY,
          fetchPolicy: 'network-only',
        });
        if (
          data?.currentUser?.firstMeetingBookedDate !==
          user?.firstMeetingBookedDate
        ) {
          setUser(data.currentUser);
        }
      } catch (ex) {
        console.log('error while fetching user:::', ex);
      }
    };
    userQuery();
    cjConfig({ userId: user?.id });

    const fetchProfile = async () => {
      try {
        const {
          data: {
            checkStudentProfileCompletion: { details },
          },
        } = await client.query({
          query: CHECK_STUDENT_PROFILE,
          variables: { userId: user?.id },
          fetchPolicy: 'no-cache',
        });
        details ? setShowCollegeFilter(false) : setShowCollegeFilter(true);
      } catch (error) {
        console.log(`error fetching Profile`, error);
      }
    };
    fetchProfile();
  };
  useEffect(mountEffect, []);

  useEffect(() => {
    const searchParam = new URLSearchParams(window.location.search);
    if (searchParam) {
      const mainAdv = searchParam.get('adv1');
      if (
        mainAdv &&
        searchParam.get('kickOff') === 'true' &&
        isAllStudentMentorsFetched
      ) {
        if (isCanceled) {
          history.push('/account/billing#payments');
          return;
        }
        const secondAdv = searchParam.get('adv2');
        const AllAdvisors = secondAdv ? [mainAdv, secondAdv] : [mainAdv];
        const advExist = AllAdvisors.every((adv) =>
          allStudentMentors?.find((item) => item?.mentor?.id == +adv)
            ? true
            : false
        );
        if (advExist && !isCanceled && !isAllVideoChatHoursUsed) {
          setAdvisorId(+mainAdv);
          setOpenCalendar(true);
        } else {
          if (
            !isPremiumUser ||
            isAllVideoChatHoursUsed ||
            (user?.premiumPackageType &&
              !allowedPremiumPackageTypesForOtherAdvisors.includes(
                user.premiumPackageType
              ))
          ) {
            setIsNoAdvisorMatch(true);
          } else {
            const nonAssociatedAdv = AllAdvisors.find(
              (adv) =>
                !allStudentMentors?.find((std) => std?.mentor?.id === +adv)
            );
            history.push(
              `/advisors/${nonAssociatedAdv}${window.location.search}`
            );
          }
        }
      }
    }
    const advisorId = searchParam.get('mentorId');
    if (process.env.REACT_APP_ENABLE_MEETING_REVIEWS === 'TRUE')
      if (advisorId) {
        const advisors =
          (allStudentMentors &&
            allStudentMentors?.map((item) => item?.mentor)) ||
          [];
        const advisor = advisors.find((adv) => adv?.id === parseInt(advisorId));
        if (advisor) {
          setMeetingRatingData({
            advisorFirstName: advisor?.firstName || '',
            mentorIds: [advisor?.id || 0],
            mentorPicture: advisor?.pictureUrl || '',
          });

          setTimeout(() => {
            setOpenStudentMentorRatingDialog(true);
          }, 1000);
        }
      }
  }, [isAllStudentMentorsFetched]);

  const refetchMeetings = () => {
    setIsMeetingBooked(true);
  };
  const history = useHistory();
  useEffect(() => {
    const parent = urlParams.get('parent');
    const addNew = urlParams.get('add');

    if (addNew) {
      urlParams.delete('add');
      history.push('/home');
    }
    if (parent) {
      urlParams.delete('parent');
      history.push('/home');
    }
    if (true) {
      localStorage.removeItem(USER_TOKEN);
      localStorage.removeItem(REDIRECT_TO_LOGIN);
      localStorage.removeItem('userRole');
    }
    createTealiumCall({
      site_vertical: tealiumConstants.SITE,
      site_portal: tealiumConstants.HOMEPAGE,
      site_product: tealiumConstants.HOMEPAGE_PRODUCT,
      site_product_section: tealiumConstants.HOMEPAGE_PRODUCT_SECTION,
      site_product_subsection: tealiumConstants.HOMEPAGE_PRODUCT_SUBSECTION,
      page_type: tealiumConstants.HOMEPAGE_PAGE_TYPE,
      content_type: tealiumConstants.HOMEPAGE_CONTENT_TYPE,
    });
  }, []);

  const currentYear = new Date().getFullYear();
  const onSubmitMeetingRating = async (rating: number, description: string) => {
    try {
      setSubmitting(true);
      const response = await client.mutate({
        mutation: ADD_MENTOR_REVIEW_RATING,
        variables: {
          studentId: user?.id,
          mentorIds: meetingRatingData.mentorIds,
          rating,
          description,
          reviewableId: meetingRatingData.meetingId,
          ReviewType: 'General',
        },
      });
      if (response) {
        setFeedbackSubmitted(true);

        setSubmitting(false);
        history.push('/home');
      }
    } catch (err) {
      console.log('Error:::', err);
    }
  };
  const handleCloseMentorRatingDialog = async () => {
    setOpenStudentMentorRatingDialog(false);
  };
  useEffect(() => {
    if (
      isPremiumUser &&
      !user?.initialKickOffDate &&
      !user?.firstMeetingBookedDate
    ) {
      const schedule = urlParams.get('schedule');
      if (!!schedule) {
        setOpenCalendar(true);
        urlParams.delete('schedule');
        setTimeout(() => {
          history.push('/home');
        }, 2000);
      }
    }
  }, []);
  if (urlParams.get('schedule') && !user) {
    return <Loader />;
  }

  return (
    <>
      <MeetingRatingFeedbackDialog
        open={openStudentMentorRatingDialog}
        onClose={handleCloseMentorRatingDialog}
        advisorFirstName={meetingRatingData.advisorFirstName}
        mentorPictureUrl={meetingRatingData.mentorPicture}
        meetingTime={meetingRatingData.meetingtime || ''}
        onSubmit={(rating: number, description: string) =>
          onSubmitMeetingRating(rating, description)
        }
        submitting={submitting}
        feedbackSubmitted={feedbackSubmitted}
      />
      {user &&
        (((user.isPremium || isTrial) && !user.timezone) ||
          !user.highSchoolYear) && <TimeZoneDialog />}

      {localStorage.getItem(MEETING_SET) !== 'true' &&
      !user?.isPremium &&
      firstTimeLoggedIn &&
      (user?.highSchoolYear === (currentYear + 1).toString() ||
        user?.highSchoolYear === (currentYear + 2).toString() ||
        user?.highSchoolYear === (currentYear + 3).toString()) &&
      (user?.aidOption === 1 ||
        user?.aidOption === 2 ||
        user?.aidOption === 4) ? (
        <HomePageCalendlyModel />
      ) : null}

      {!urlParams.get('schedule') &&
        firstTimeLoggedIn &&
        !isTrial &&
        !isCapEnrollee &&
        !user?.isParent &&
        (!user?.isPremium || user?.timezone) &&
        user?.highSchoolYear &&
        !(
          (user?.highSchoolYear === (currentYear + 1).toString() ||
            user?.highSchoolYear === (currentYear + 2).toString() ||
            user?.highSchoolYear === (currentYear + 3).toString()) &&
          (user?.aidOption === 1 ||
            user?.aidOption === 2 ||
            user?.aidOption === 4) &&
          localStorage.getItem(MEETING_SET) !== 'true'
        ) && <VideoModal />}
      <BookingUnavailableDialog
        open={isNoAdvisorMatch}
        setOpen={setIsNoAdvisorMatch}
        isAllVideoChatHoursUsed={isAllVideoChatHoursUsed}
      />

      <Container maxWidth={false}>
        <AdvisorBookingModal
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          setIsMeetingBooked={setIsMeetingBooked}
          advisorId={advisorId}
          refetch={refetchMeetings}
          intakeWidgetCondition={intakeWidgetCondition || false}
          setIsTrialMeetingBooked={setIsTrialMeetingBooked}
          defaultAdvisorIds={defaultAdvisorIds}
          setAdvisorId={setAdvisorId}
        />

        <Grid className={classes.upperClassHeader}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            className="graduateResponsive"
          >
            <Box
              display="flex"
              alignItems="center"
              className="graduateResponsivePadding"
            >
              {user && (
                <Typography variant="h3">{` Hi, ${user?.firstName}!`}</Typography>
              )}

              {user?.graduated && (
                <Box className={classes.graduatedBox} ml="24px">
                  <img src={GRADUATE_CAP_ICON} alt="cap" />
                  <Typography variant="body1">Graduated</Typography>
                </Box>
              )}
            </Box>

            <Hidden only={['md', 'lg', 'xl']}>
              {!isPremiumUser && !isTrial && (
                <a href="https://www.collegeadvisor.com/pricing">
                  <Button variant="contained" className={classes.upgradeButton}>
                    <img src={PHONE_UPGRADE_IMAGE} alt="upgrade" />
                    Upgrade
                  </Button>
                </a>
              )}
            </Hidden>
          </Box>

          <Grid className={classes.addParentWrapper}>
            <Typography variant="h6">
              {year ? (
                'Class of ' + year
              ) : (
                <Link to="/account/profile">Set Grad Year</Link>
              )}
              {year && (
                <Link to="/account/profile">
                  <img src={EDIT_IMAGE} alt="edit" />
                </Link>
              )}
            </Typography>
            <Grid className={classes.addParentAccount}>
              {!!!user?.parents?.length ? (
                <Link to="/account/parent-accounts">
                  Add Parent/Guardian Account
                </Link>
              ) : (
                user.parents.map((item, index) => {
                  if (item?.firstName || item?.lastName)
                    return (
                      <Typography variant="body1" key={index}>
                        {!item.isPasswordSet ? (
                          <span>Parent/Guardian Invite Sent: </span>
                        ) : (
                          <span>Parent/Guardian:</span>
                        )}
                        {`${item?.firstName || ''} ${item?.lastName || ''}`}
                      </Typography>
                    );
                })
              )}
            </Grid>
          </Grid>
        </Grid>

        {isPremiumUser && isAllVideoChatHoursUsed && !user?.graduated && (
          <VideoChatHoursUsedAlert />
        )}

        <Query<SmartMatchBannerQuery>
          fetchPolicy="no-cache"
          query={SMART_MATCH_BANNER_QUERY}
        >
          {({ data, refetch }) => {
            if (
              !data ||
              !data.smartMatchBanner ||
              !data.smartMatchBanner.success
            ) {
              return null;
            }

            const { success, message } = data.smartMatchBanner;

            if (!success || message === 'Do not show banner') {
              return null;
            }
            console.log('data.smartMatchBanne', data.smartMatchBanner);

            return <UpdateProfile refetch={refetch} />;
          }}
        </Query>

        {!user?.isPremium && !!user?.studentTrial?.trialCompletedAt && (
          <Card className={classes.activityPendingCard}>
            <Typography variant="h5">
              Thanks for letting us join you on your college admissions journey
              !
            </Typography>
            <Typography variant="body1">
              Schedule a free strategy meeting to learn more about continuing
              your personalized advising experience.
            </Typography>

            <Box>
              <a href={`/schedule`} target="-blank" style={{ color: '#fff' }}>
                <Button
                  color="primary"
                  variant="contained"
                  className="buttonContained"
                >
                  Schedule a Meeting
                </Button>
              </a>
              <a href={`/client-enrollment`} target="_blank" rel="noreferrer">
                <Button
                  color="primary"
                  variant="outlined"
                  className="buttonOutlined"
                  style={{ backgroundColor: '#fff' }}
                >
                  View available packages
                </Button>
              </a>
            </Box>
          </Card>
        )}

        <Grid container spacing={3} className={classes.homeScreenContainer}>
          {isTrial ? (
            <>
              <Grid item lg={7} md={12} sm={12} xs={12}>
                <Query<allAdvisorBookingsQuery>
                  query={ALL_ADVISOR_BOOKINGS}
                  fetchPolicy="network-only"
                  variables={{
                    status: 'upcoming',
                    sortByDate: true,
                  }}
                >
                  {({ data, loading, refetch }) => {
                    if (loading) return <Loader />;
                    return (
                      <MeetingForTrial
                        handleCalendarClick={(e: number) => {
                          setAdvisorId(e);
                          setOpenCalendar(true);
                        }}
                        advisorBookings={
                          data?.allAdvisorBookings?.advisorBookings
                        }
                        refetch={refetch}
                        isMeetingBooked={isMeetingBooked}
                        setIsMeetingBooked={setIsMeetingBooked}
                        isTrialCompleted={
                          !!user?.studentTrial?.trialCompletedAt
                        }
                        isTrialMeetingBooked={isTrialMeetingBooked}
                      />
                    );
                  }}
                </Query>
              </Grid>

              <Grid item lg={5} md={12} sm={12} xs={12}>
                <Query<getUserUpcomingAndPastWebinarsQuery>
                  query={GET_WEBINARS}
                  fetchPolicy="network-only"
                >
                  {({ data, loading }) => {
                    if (loading) return <Loader />;
                    return !data?.getUserUpcomingAndPastWebinars?.upcoming
                      ?.length && !user?.isPremium ? (
                      <a href="/webinars">
                        <SimpleCard
                          bgColor="#52C9BB"
                          heading="WEBINARS"
                          body="Stream our 400+ FREE webinars to assist you across your admissions journey."
                          img={WEBINAR_BG_IMAGE}
                          className="watch"
                          BtnImg={NEXT_ARROW_ICON}
                          bgSize="260px"
                          textWidth="207px"
                          bgLayerImg={BG_LAYER_IMAGE}
                        />
                      </a>
                    ) : (
                      <Link
                        to={'/webinars'}
                        style={{ opacity: '40%', pointerEvents: 'none' }}
                      >
                        <WebinarRouteCard />
                      </Link>
                    );
                  }}
                </Query>

                {!user?.isPremium ? (
                  <Link to="/extracurricular-opportunities">
                    <SimpleCard
                      bgColor="#2F80ED"
                      className="explore"
                      heading="OPPORTUNITIES DATABASE"
                      body="Explore pre-college programs, summer experiences, and more extracurriculars."
                      img={OPP_BG_IMG}
                      BtnImg={NEXT_ARROW_ICON}
                      bgSize="200px"
                      textWidth="289px"
                      bgPosition="right -11px bottom 15px"
                      bgLayerImg={BG_LAYER_IMAGE}
                    />
                  </Link>
                ) : (
                  <Link
                    to="/extracurricular-opportunities"
                    style={{ opacity: '40%', pointerEvents: 'none' }}
                  >
                    <ExtraOpportunitiesCard />
                  </Link>
                )}
              </Grid>
            </>
          ) : (
            <>
              {(process.env.REACT_APP_PRE_MEETING_FEATURE === 'TRUE' ||
                (user && [919783, 964808, 966649].includes(user?.id))) &&
                !user?.isPremium && (
                  <Query<getMeetingTime>
                    query={GET_MEETING_TIME}
                    fetchPolicy="network-only"
                    variables={{
                      zoom: true,
                      userId: user?.id,
                    }}
                  >
                    {({ data, loading }) => {
                      if (loading) return <Loader />;
                      if (!data?.getMeetingTime?.timeRemaining) {
                        return null;
                      }
                      if (data?.getMeetingTime) {
                        const {
                          timeRemaining,
                          dateWithTimeZone,
                          followUp,
                          specialistName,
                          meetingStatus,
                          link,
                          email,
                          phoneNumber,
                          stage,
                          coordinator,
                        } = data?.getMeetingTime;
                        if (timeRemaining) {
                          //is between start time and after 1 hour adding
                          const meetingStartTime = moment(timeRemaining);
                          const meetingEndTime = moment(timeRemaining).add(
                            1,
                            'hour'
                          );
                          setIsPreMeeting(true);

                          if (stage === meetingStages.closedLost) {
                            return null;
                          } else if (
                            meetingStatuses.showRescheduleBanner(
                              meetingStatus || ''
                            )
                          ) {
                            return (
                              <Grid item sm={12} xs={12}>
                                <PreMeeting
                                  meetingStart={false}
                                  link={link || ''}
                                  followUp={followUp || false}
                                  dateTime={dateWithTimeZone || ''}
                                  cancelled={true}
                                  specialistName={specialistName || ''}
                                />
                              </Grid>
                            );
                          } else if (
                            meetingStatus !== meetingStatuses.cancel &&
                            meetingStatus !== meetingStatuses.noShow &&
                            meetingStatus !== meetingStatuses.complete &&
                            meetingStatus !==
                              meetingStatuses.reschedulePending &&
                            moment().isBetween(
                              meetingStartTime,
                              meetingEndTime
                            ) &&
                            link
                          ) {
                            return (
                              <Grid item sm={12} xs={12}>
                                <PreMeeting
                                  meetingStart={true}
                                  link={link}
                                  followUp={followUp || false}
                                  dateTime={dateWithTimeZone || ''}
                                />
                              </Grid>
                            );
                          } else if (
                            meetingStatus !== meetingStatuses.complete &&
                            meetingStartTime.isAfter(moment()) &&
                            link
                          ) {
                            return (
                              <Grid item sm={12} xs={12}>
                                <PreMeeting
                                  meetingStart={false}
                                  followUp={followUp || false}
                                  dateTime={dateWithTimeZone || ''}
                                  link={link}
                                  specialistName={specialistName || ''}
                                  coordinator={coordinator}
                                  email={email || ''}
                                  phoneNumber={phoneNumber || ''}
                                />
                              </Grid>
                            );
                          } else if (
                            meetingStatus === meetingStatuses.actualMeeting &&
                            (stage === meetingStages.followUpTentative ||
                              stage === meetingStages.followUpScheduled) &&
                            meetingEndTime.isBefore(moment())
                          ) {
                            return (
                              <Grid item sm={12} xs={12}>
                                <PreMeeting
                                  meetingStart={false}
                                  followUp={followUp || false}
                                  dateTime={dateWithTimeZone || ''}
                                  link={link || ''}
                                  specialistName={specialistName || ''}
                                  completed={true}
                                  phoneNumber={phoneNumber || ''}
                                  email={email || ''}
                                />
                              </Grid>
                            );
                          } else {
                            setIsPreMeeting(false);
                            return null;
                          }
                        }
                      }
                    }}
                  </Query>
                )}

              <Grid item lg={7} md={7} sm={12} xs={12}>
                {user?.shareDriveUrl && (
                  <>
                    <Link
                      to={{ pathname: user?.shareDriveUrl }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Card className={classes.accessGoogleDrive}>
                        <img src={DRIVE_ICON} alt="drive" />

                        <Typography variant="body1" className="accessText">
                          Access your Google Drive folder
                        </Typography>

                        <IconButton>
                          <img src={NEXT_ARROW_ICON} alt="next" />
                        </IconButton>
                      </Card>
                    </Link>
                  </>
                )}
                {packageCardCondition && (
                  <>
                    <Grid className={classes.packageCardMobile}>
                      {!user?.graduated && !isCanceled && (
                        <Query<allAdvisorBookingsQuery>
                          query={ALL_ADVISOR_BOOKINGS}
                          fetchPolicy="network-only"
                          variables={{
                            status: 'upcoming',
                            sortByDate: true,
                          }}
                        >
                          {({ data, loading, refetch }) => {
                            if (loading) return <Loader />;
                            return (
                              <MeetingsCard
                                advisorBookings={
                                  data?.allAdvisorBookings?.advisorBookings
                                }
                                refetchMeeting={refetch}
                                isMeetingBooked={isMeetingBooked}
                                setIsMeetingBooked={setIsMeetingBooked}
                                setCalendarAdvisorId={setAdvisorId}
                                setDefaultAdvisorIds={setDefaultAdvisorIds}
                                setOpenCalendar={setOpenCalendar}
                              />
                            );
                          }}
                        </Query>
                      )}
                      <PackageCardMobile
                        openCalendar={setOpenCalendar}
                        setAdvisorId={setAdvisorId}
                        allStudentMentors={allStudentMentors}
                      />
                    </Grid>
                  </>
                )}

                {process.env.REACT_APP_ENABLE_VCCS_WIDGET === 'TRUE' &&
                (!user?.isPremium ||
                  user?.initialKickOffDate ||
                  (user?.firstMeetingBookedDate &&
                    user.firstMeetingBookedDate?.endTime <
                      Date.now() / 1000)) ? (
                  <VccsWidgetCard />
                ) : null}

                {year &&
                (+year === Number(process.env.REACT_APP_CURRENT_GRAD_YEAR) ||
                  +year ===
                    Number(process.env.REACT_APP_CURRENT_GRAD_YEAR) + 1) ? (
                  <ApplicationsCard />
                ) : null}
                <BlogPostsCard />
              </Grid>

              <Grid item lg={5} md={5} sm={12} xs={12}>
                {intakeWidgetCondition && (
                  <Query<allAdvisorBookingsQuery>
                    query={ALL_ADVISOR_BOOKINGS}
                    fetchPolicy="network-only"
                    variables={{
                      status: 'upcoming',
                      sortByDate: true,
                    }}
                  >
                    {({ data, loading, refetch }) => {
                      if (loading) return <Loader />;
                      return (
                        <GetStartCard
                          handleCalendarClick={(e: number) => {
                            setAdvisorId(e);
                            setOpenCalendar(true);
                          }}
                          advisorBookings={
                            data?.allAdvisorBookings?.advisorBookings
                          }
                          refetch={refetch}
                          isMeetingBooked={isMeetingBooked}
                          setIsMeetingBooked={setIsMeetingBooked}
                          consultant={
                            isCapEnrollee
                              ? allStudentMentors?.[0]?.consultant
                              : null
                          }
                        />
                      );
                    }}
                  </Query>
                )}

                <Grid className={classes.packageCardDesktop}>
                  {packageCardCondition ? (
                    <>
                      {!user?.graduated && !isCanceled && (
                        <Query<allAdvisorBookingsQuery>
                          query={ALL_ADVISOR_BOOKINGS}
                          fetchPolicy="network-only"
                          variables={{
                            status: 'upcoming',
                            sortByDate: true,
                          }}
                        >
                          {({ data, loading, refetch }) => {
                            if (loading) return <Loader />;
                            return (
                              <MeetingsCard
                                advisorBookings={
                                  data?.allAdvisorBookings?.advisorBookings
                                }
                                refetchMeeting={refetch}
                                isMeetingBooked={isMeetingBooked}
                                setIsMeetingBooked={setIsMeetingBooked}
                                setCalendarAdvisorId={setAdvisorId}
                                setDefaultAdvisorIds={setDefaultAdvisorIds}
                                setOpenCalendar={setOpenCalendar}
                              />
                            );
                          }}
                        </Query>
                      )}
                      <PackageCard
                        openCalendar={setOpenCalendar}
                        setAdvisorId={setAdvisorId}
                        Theme={null}
                        graduateSchool={undefined}
                      />
                    </>
                  ) : (
                    !isPremiumUser &&
                    !isCanceled &&
                    !isPreMeeting && <UpgradeCard />
                  )}
                </Grid>

                {!user?.isPremium ? (
                  <a href="/webinars">
                    <SimpleCard
                      bgColor="#52C9BB"
                      heading="WEBINARS"
                      body="Stream our 400+ FREE webinars to assist you across your admissions journey."
                      img={WEBINAR_BG_IMAGE}
                      className="watch"
                      BtnImg={NEXT_ARROW_ICON}
                      bgSize="260px"
                      textWidth="207px"
                      bgLayerImg={BG_LAYER_IMAGE}
                    />
                  </a>
                ) : (
                  <Link to={'/webinars'}>
                    <WebinarRouteCard />
                  </Link>
                )}

                {!user?.isPremium ? (
                  <Link to="/extracurricular-opportunities">
                    <SimpleCard
                      bgColor="#2F80ED"
                      className="explore"
                      heading="OPPORTUNITIES DATABASE"
                      body="Explore pre-college programs, summer experiences, and more extracurriculars."
                      img={OPP_BG_IMG}
                      BtnImg={NEXT_ARROW_ICON}
                      bgSize="200px"
                      textWidth="289px"
                      bgPosition="right -11px bottom 15px"
                      bgLayerImg={BG_LAYER_IMAGE}
                    />
                  </Link>
                ) : (
                  <Link to="/extracurricular-opportunities">
                    <ExtraOpportunitiesCard />
                  </Link>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <FooterNew />
    </>
  );
};
export default StudentHomePage;
