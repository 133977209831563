import Banner_Img_1 from './summer-opportunity-1.svg';
import Banner_Img_2 from './summer-opportunity-2.svg';
import Banner_Img_3 from './summer-opportunity-3.svg';
import Banner_Img_4 from './summer-opportunity-4.svg';
import Banner_Img_5 from './summer-opportunity-5.svg';
import Banner_Img_6 from './summer-opportunity-6.svg';

import CARD from './CARD.png';
import LOCK from './Lock-Grey.png';
import American_Express_2 from './american_express-2.svg';
import American_Express from './american_express.png';
import Area_bg_3 from './area-3.png';
import BANK_IMAGE from './bank-icon.png';
import LOCATION_IMG from './card-icon-1.svg';
import TIME_IMG from './card-icon-2.svg';
import STATUS_IMG from './card-icon-3.svg';
import Caution from './caution-icon-url.svg';
import CLIENT_ENROLLMENT_BG from './client-enrollment-bg.png';
import CreditCard from './credit-card.svg';
import Discover from './discover.png';
import EDIT from './edit-vector.svg';
import EXC_VECTOR from './exc_vector.svg';
import QUESTIONS from './help-support.svg';
import HEADER_LOGO from './logo-header.svg';
import MAIL_ICON from './mail-icon.png';
import Master_Card from './mastercard.png';
import CHECKED_1 from './meeting-verified.svg';
import PLUS_CIRCLE from './plus-circle.svg';
import RED_VIDEO_ICON from './red_video_icon.svg';
import Union_Pay from './unionpay.svg';
import VIDEO_ICON from './videoIcon.svg';
import Visa_Card from './visa_card.png';

import DiamondIcon2 from '../img/diamond-icon2.svg';
import PrivcacyIcon from '../img/privacyoptions.svg';

import AcceptedApplication from '../img/accepted-application.png';
import CelebrateIcon from '../img/celebrate-icon.svg';
import DPKG_1 from '../img/dpkg_1.jpg';
import DPKG_2 from '../img/dpkg_2.jpg';
import DPKG_3 from '../img/dpkg_3.jpg';

import AddIcon from '../img/add-icon.svg';
import ADD_ICON from '../img/add-plus-icon.svg';
import BREADCRUMB_LINK_IMG from '../img/back-arrow-hub.svg';
import COLLEGE_ADD_IMG from '../img/check-circle-added.svg';
import SELECT_ARROW_DOWN from '../img/chevron-down-white.svg';
import CollegeDefaultImg from '../img/college-default-img.svg';
import DELETE_ICON from '../img/delete-icon.svg';
import DIALOG_CLOSE_ICON from '../img/dialog-close.svg';
import DIALOG_CLOSE_GRAY_ICON from '../img/dialog-close-gray.svg';
import DRAWER_CLOSE_ICON from '../img/drawer_close.svg';
import DRIVE_ICON from '../img/drive.png';
import EditPencilIcon from '../img/edit-pencil.svg';
import FILE_ICON from '../img/file.svg';
import GOOGLE_DRIVE from '../img/google_drive.png';
import INFO_ICON from '../img/info-graduated-icon.svg';
import MENU_CIRCLE from '../img/menu-button.svg';
import MENU_DELETE_ICON from '../img/menu-delete.svg';
import Milestone1Icon from '../img/milestone-icon-1.svg';
import Milestone2Icon from '../img/milestone-icon-2.svg';
import Milestone3Icon from '../img/milestone-icon-3.svg';
import Milestone4Icon from '../img/milestone-icon-4.svg';
import Milestone5Icon from '../img/milestone-icon-5.svg';
import Milestone6Icon from '../img/milestone-icon-6.svg';
import Milestone7Icon from '../img/milestone-icon-7.svg';
import Milestone8Icon from '../img/milestone-icon-package.svg';
import NETWORK from '../img/network.png';
import PENCIL_EDIT_ICON from '../img/pencil-edit.svg';
import REQUIRED_TRUE_ICON from '../img/required-true.svg';
import DELETE_ATTACH_IMG from '../img/trash-delete.svg';
import TrashIcon from '../img/trash-icon.svg';

import MEETING from '../img/meeting.svg';
import Personalized_Img from '../img/personalized.svg';

import Guide_Avatar_Img_1 from '../img/avatar-guide-1.png';
import Envelope_Img from '../img/envelope.svg';
import Get_Started_Bg_Img from '../img/get-started.png';
import Guide_Placeholder_Img from '../img/placeholder.png';

import ADD_DISABLE_ICON from '../img/add-plus-icon-disable.svg';
import CALL_ICON from '../img/call-college.svg';
import COLLEGE_IMAGE from '../img/college.svg';
import NO_IMAGE from '../img/empty-badge.svg';
import EMPTY_FILE_RECT_IMG from '../img/empty-checkbox.svg';
import GLOBE_ICON from '../img/globe-college-website.svg';
import LOCATION_MARKER from '../img/location-marker.svg';
import Magoosh from '../img/magoosh.png';
import MBA_CHECK from '../img/MBA-Check.png';
import EARTH_GLOBE from '../img/earth-globe.png';
import AdmissionExpert from '../img/avatar-advisor.svg';
import CheckListIcon from '../img/checklist-icon.svg';
import PlusDarkIcon from '../img/plus-icon-dark.svg';
import DefaultProfileImage from '../img/empty-badge.svg';
import CollegeDefaultIcon from '../img/college-default-icon.svg';
import PROFILE_BADGE_IMG from '../img/profile-badge.png';
import MILESTONE_DARK_IMG from '../img/milestone-dark.svg';
import REFRESH_IMG from '../img/refresh.svg';

export default {
  Banner_Img_1: Banner_Img_1,
  Banner_Img_2: Banner_Img_2,
  Banner_Img_3: Banner_Img_3,
  Banner_Img_4: Banner_Img_4,
  Banner_Img_5: Banner_Img_5,
  Banner_Img_6: Banner_Img_6,
  Area_bg_3: Area_bg_3,
  LOCATION_IMG,
  TIME_IMG,
  STATUS_IMG,
  EDIT,
  CARD,
  HEADER_LOGO,
  Lock: LOCK,
  American_Express,
  Master_Card,
  Visa_Card,
  Discover,
  American_Express_2,
  Union_Pay,
  CreditCard,
  EXC_VECTOR,
  PLUS_CIRCLE,
  MAIL_ICON,
  CLIENT_ENROLLMENT_BG,
  VIDEO_ICON,
  RED_VIDEO_ICON,
  Caution,
  BANK_IMAGE,
  CHECKED_1,
  QUESTIONS,
  PrivcacyIcon,
  DiamondIcon2,
  DPKG_1,
  DPKG_2,
  DPKG_3,
  AcceptedApplication,
  CelebrateIcon,
  Milestone1Icon,
  Milestone2Icon,
  Milestone3Icon,
  Milestone4Icon,
  Milestone5Icon,
  Milestone6Icon,
  Milestone7Icon,
  AddIcon,
  BREADCRUMB_LINK_IMG,
  COLLEGE_ADD_IMG,
  SELECT_ARROW_DOWN,
  CollegeDefaultImg,
  DIALOG_CLOSE_ICON,
  DRAWER_CLOSE_ICON,
  DRIVE_ICON,
  FILE_ICON,
  GOOGLE_DRIVE,
  INFO_ICON,
  MENU_DELETE_ICON,
  TrashIcon,
  DELETE_ATTACH_IMG,
  MENU_CIRCLE,
  PENCIL_EDIT_ICON,
  ADD_ICON,
  EditPencilIcon,
  REQUIRED_TRUE_ICON,
  DELETE_ICON,
  MEETING,
  Personalized_Img,
  Guide_Avatar_Img_1,
  Guide_Placeholder_Img,
  Envelope_Img,
  Get_Started_Bg_Img,
  NO_IMAGE,
  COLLEGE_IMAGE,
  Milestone8Icon,
  CALL_ICON,
  GLOBE_ICON,
  LOCATION_MARKER,
  EMPTY_FILE_RECT_IMG,
  ADD_DISABLE_ICON,
  NETWORK,
  Magoosh,
  MBA_CHECK,
  DIALOG_CLOSE_GRAY_ICON,
  EARTH_GLOBE,
  AdmissionExpert,
  CheckListIcon,
  PlusDarkIcon,
  DefaultProfileImage,
  CollegeDefaultIcon,
  PROFILE_BADGE_IMG,
  MILESTONE_DARK_IMG,
  REFRESH_IMG
};
