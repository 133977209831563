import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts';
import {
  Box,
  Card,
  Container,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import GRADUATE_CAP_ICON from '../../img/graduated-icon-cap.svg';
import { homePageStyles } from './homePagestyles';
import SimpleCard from '../main/home/SimpleCard';
import WEBINAR_BG_IMAGE from '../../img/webinar-bg-img.png';
import BG_LAYER_IMAGE from '../../img/webinar-card-bg-layer.png';
import NEXT_ARROW_ICON from '../../img/light-arrow-next.svg';
import MBABlogPostCards from './MBABlogPostCards';
import { ALL_ADVISOR_BOOKINGS } from '../main/home/BookingMeeting/gql';
import { Query } from 'react-apollo';
import { allAdvisorBookingsQuery } from '../main/home/BookingMeeting/gql/__generated__/allAdvisorBookingsQuery';
import GetStartCard from '../main/intakeForm/GetStartCard';
import Loader from '../../components/Loader';
import MeetingsCard from '../main/home/MeetingsCard';
import { ClientStatus } from '../../common/constants';
import PackageCard from '../main/home/PackageCard';
import INFO_ICON from '../../img/info-graduated-icon.svg';
import DRIVE_ICON from '../../img/drive-icon.png';
import { AdvisorBookingModal } from '../main/home/StudentHomePage';
import { GET_ALL_STUDENT_MENTORS } from '../../graphql/queries/studentMentors';
import { getAllStudentMentors_allStudentMentors } from '../../graphql/queries/studentMentors/__generated__/getAllStudentMentors';
import client from '../../apollo';

const MBAHomePage = () => {
  const { user } = useContext(AppContext);
  const classes = homePageStyles();
  const [openCalendar, setOpenCalendar] = useState(false);
  const [advisorId, setAdvisorId] = useState<number | null>(null);
  const [isMeetingBooked, setIsMeetingBooked] = useState(false);
  const [allStudentMentors, setAllStudentMentors] = useState<
    (getAllStudentMentors_allStudentMentors | null)[] | null
  >(null);

  const isCanceled =
    user?.clientStatus === ClientStatus.BAD_DEBT ||
    user?.clientStatus === ClientStatus.CANCELED;

  const intakeWidgetCondition =
    user?.isPremium &&
    !user?.initialKickOffDate &&
    (!user?.firstMeetingBookedDate ||
      user?.firstMeetingBookedDate?.endTime > Date.now() / 1000);

  const packageCardCondition =
    (user?.isPremium &&
      (user?.initialKickOffDate ||
        (user?.firstMeetingBookedDate &&
          user.firstMeetingBookedDate?.endTime < Date.now() / 1000))) ||
    false;

  const refetchMeetings = () => {
    setIsMeetingBooked(true);
  };

  useEffect(() => {
    const fetchAllStudentMentors = async () => {
      const { data } = await client.query({
        query: GET_ALL_STUDENT_MENTORS,
        fetchPolicy: 'network-only',
      });
      if (data && data.allStudentMentors) {
        setAllStudentMentors(data.allStudentMentors);
      }
    };

    fetchAllStudentMentors();
  }, []);

  return (
    <Container maxWidth={false}>
      {advisorId && (
        <AdvisorBookingModal
          openCalendar={openCalendar}
          setOpenCalendar={setOpenCalendar}
          setIsMeetingBooked={setIsMeetingBooked}
          advisorId={advisorId}
          refetch={refetchMeetings}
          intakeWidgetCondition={intakeWidgetCondition || false}
        />
      )}
      <Grid className={classes.upperClassHeader}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          className="graduateResponsive"
        >
          <Box
            display="flex"
            alignItems="center"
            className="graduateResponsivePadding"
          >
            {user && (
              <Typography variant="h3">{` Hi, ${user?.firstName}!`}</Typography>
            )}

            {user?.graduated && (
              <Box className={classes.graduatedBox} ml="24px">
                <img src={GRADUATE_CAP_ICON} alt="cap" />
                <Typography variant="body1">Graduated</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      {!allStudentMentors?.length && (
        <Card className={classes.advisorMatchCard}>
          <Grid container>
            <Box marginRight={2}>
              <img src={INFO_ICON} alt="caution" className="img" />
            </Box>
            <Box className="textWrapper">
              <Typography variant="h5">
                Your advisor match is on the way!
              </Typography>
              <Typography variant="body1">
                Welcome to the CollegeAdvisor.com MBA Program. You will be
                matched with an advisor within 48 hours.
              </Typography>
            </Box>
          </Grid>
        </Card>
      )}
      <Grid container spacing={3} className={classes.homeScreenContainer}>
        <Grid item lg={7} md={12} sm={12} xs={12}>
          <Grid className={classes.packageCardDesktop}>
            {user?.shareDriveUrl && (
              <>
                <Link
                  to={{ pathname: user?.shareDriveUrl }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Card className={classes.accessGoogleDrive}>
                    <img src={DRIVE_ICON} alt="drive" />

                    <Typography variant="body1" className="accessText">
                      Access your Google Drive folder
                    </Typography>

                    <IconButton>
                      <img src={NEXT_ARROW_ICON} alt="next" />
                    </IconButton>
                  </Card>
                </Link>
              </>
            )}

            {allStudentMentors?.length ? (
              <>
                {!user?.graduated && !isCanceled && (
                  <Query<allAdvisorBookingsQuery>
                    query={ALL_ADVISOR_BOOKINGS}
                    fetchPolicy="network-only"
                    variables={{
                      status: 'upcoming',
                      sortByDate: true,
                    }}
                  >
                    {({ data, loading, refetch }) => {
                      if (loading) return <Loader />;
                      return (
                        <MeetingsCard
                          advisorBookings={
                            data?.allAdvisorBookings?.advisorBookings
                          }
                          refetchMeeting={refetch}
                          isMeetingBooked={isMeetingBooked}
                          setIsMeetingBooked={setIsMeetingBooked}
                          setCalendarAdvisorId={setAdvisorId}
                          setOpenCalendar={setOpenCalendar}
                        />
                      );
                    }}
                  </Query>
                )}
              </>
            ) : (
              ''
            )}
          </Grid>
          <MBABlogPostCards />
        </Grid>
        <Grid item lg={5} md={5} sm={12} xs={12}>
          {intakeWidgetCondition && allStudentMentors?.length ? (
            <Query<allAdvisorBookingsQuery>
              query={ALL_ADVISOR_BOOKINGS}
              fetchPolicy="network-only"
              variables={{
                status: 'upcoming',
                sortByDate: true,
              }}
            >
              {({ data, loading, refetch }) => {
                if (loading) return <Loader />;
                return (
                  <GetStartCard
                    handleCalendarClick={(e: number) => {
                      setAdvisorId(e);
                      setOpenCalendar(true);
                    }}
                    advisorBookings={data?.allAdvisorBookings?.advisorBookings}
                    refetch={refetch}
                    isMeetingBooked={isMeetingBooked}
                    setIsMeetingBooked={setIsMeetingBooked}
                    isMBAProgram={user?.isMBAGrad || false}
                  />
                );
              }}
            </Query>
          ) : (
            ''
          )}

          <Grid className={classes.packageCardDesktop}>
            {packageCardCondition && (
              <PackageCard
                openCalendar={setOpenCalendar}
                setAdvisorId={setAdvisorId}
                Theme={null}
                graduateSchool={undefined}
              />
            )}
          </Grid>

          <a href="/webinars">
            <SimpleCard
              bgSize="260px"
              bgColor="#52C9BB"
              textWidth="207px"
              className="watch"
              heading="WEBINARS"
              img={WEBINAR_BG_IMAGE}
              BtnImg={NEXT_ARROW_ICON}
              bgLayerImg={BG_LAYER_IMAGE}
              body="Register and Watch replays of our LIVE MBA Webinars."
            />
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default MBAHomePage;
